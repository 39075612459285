import React, { useContext, useState } from "react";
import { ThemeContext } from "../layout/Layout";
import rebrimg from "../images/rebr.svg";
import stepsimg from "../images/stepsinvlovd.png";
import stapa from "../images/stepa.png";
import forimg from "../images/fprimg.png";

const caseStudiesDetail = [
  { title: "Rebranding a Luxury Hotel ", description: "How we rebranded A Luxury Hotel in Pakistan", image: rebrimg, link: "" },
  { title: "Rebranding a Luxury Hotel ", description: "How we rebranded A Luxury Hotel in Pakistan", image: stepsimg, link: "" },
  { title: "Rebranding a Luxury Hotel ", description: "How we rebranded A Luxury Hotel in Pakistan", image: stapa, link: "" },
  { title: "Rebranding a Luxury Hotel ", description: "How we rebranded A Luxury Hotel in Pakistan", image: forimg, link: "" },
];

const CaseStudies = ({ imgSrc, title, description, link }) => {
  return (
    <div className="col-span-1 cursor-pointer">
      <div className="w-full aspect-square overflow-hidden">
        <img alt="" className="w-full h-full object-cover" src={imgSrc} />
      </div>
      <div className="pt-4 text-2xl font-semibold uppercase">{title}</div>
      <p className=" capitalize">{description}</p>
    </div>
  );
};

export default function Index() {
  const theme = useContext(ThemeContext);
  const [hidden, setHidden] = useState(true);

  return (
    <>
      <div className={`fixed inset-0  grid grid-cols-1 z-50 ${hidden ? "hidden" : ""}`}>
        <svg className="overlay w-full h-full" width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
          <path id="overlayPath" className="overlay__path" fill={theme === "dark" ? "white" : "black"} vectorEffect="non-scaling-stroke" d="M 0 100 V 100 Q 50 100 100 100 V 100 z" />
        </svg>
      </div>
      <div className="relative z-20">
        <div className="w-screen relative z-50 flex flex-col items-center justify-center ">
          <div className="container px-8 mx-auto lg:py-80 py-40">
            <span data-sal="fade" data-sal-delay="300" data-sal-duration="1000" data-sal-easing="ease" className="text-primary-100 uppercase lg:text-8xl text-5xl text-left leading-none radio mb-10">
              Helping Ambitious Brands Stay <strong>Above</strong>
              <i> Their Competition.</i>
            </span>
            <div className="mt-10 flex flex-col lg:flex-row lg:items-center items-start justify-start  lg:w-1/2">
              <span data-sal="slide-up" data-sal-duration="500" data-sal-delay="800" data-sal-easing="ease" className="helv lg:my-0 my-2 uppercase hover-line cursor-pointer text-xl">
                Approach & Services
              </span>
              <span data-sal="slide-up" data-sal-duration="500" data-sal-delay="1000" data-sal-easing="ease" className="helv lg:my-0 my-2 uppercase hover-line cursor-pointer text-xl lg:ml-10">
                Case Studies & Insights
              </span>
            </div>
          </div>
          <div
            className="w-screen h-screen"
            style={{ background: "url('https://images.pexels.com/photos/2156883/pexels-photo-2156883.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260') center center no-repeat", backgroundSize: "cover", backgroundAttachment: "fixed" }}
          ></div>
        </div>
      </div>
      <div className="w-screen relative z-20 pt-16 pb-32">
        <div className="container px-8 mx-auto">
          <hr className={`w-full h-0 border-t mb-2 ${theme === "dark" ? "border-white" : "border-black"}`} />
          <div className="w-full">
            <div className="text-base px-1 flex items-center justify-between font-light helv uppercase">
              <i> About </i>
              <p>01</p>
            </div>
          </div>
          <div className="grid mt-8 grid-cols-2  w-full">
            <div className="lg:w-10/12 w-full col-span-2 md:col-span-1 lg:pr-8 flex flex-col">
              <p className=" lg:text-6xl text-3xl">
                <span className="helv text-black uppercase font-semibold"> Theorem is a People driven </span>
                <span className="italic text-primary-100 radio font-light capitalize">technology company.</span>
              </p>
            </div>
            <div className="md:w-1/2 w-full col-span-2 md:col-span-1 mt-8 md:mt-0 ">
              <div className="text-xl">
                <p>We focus on digital design, strategic marketing, and all things technology to better businesses and brand experiences.</p>
                <br />
                <p>We pride ourselves in being fully transparent. We are clear on every step we take, and you will always be involved in all business decisions.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-screen relative z-20 pt-16 pb-32">
        <div className="container px-8 mx-auto">
          <hr className={`w-full h-0 border-t mb-2 ${theme === "dark" ? "border-white" : "border-black"}`} />
          <div className="w-full">
            <div className="text-base px-1 flex items-center justify-between font-light helv uppercase">
              <i> Approach </i>
              <p>02</p>
            </div>
          </div>
          <div className="w-full mt-8 grid lg:grid-cols-2 grid-cols-1 gap-16">
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-8 col-span-1 lg:col-start-2">
              <div className="col-span-1 lg:h-32">
                <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500" data-sal-easing="ease" className="text-2xl font-semibold uppercase">
                  Project discovery
                </div>
              </div>
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="800" data-sal-easing="ease" className="md:col-span-1 col-span-2 lg:h-32">
                <p className="lg:w-2/3 w-full">Our key strategies are based on simplifying complex things to achieve the best results upon your challenges.</p>
              </div>
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500" data-sal-easing="ease" className="md:col-span-1 col-span-2 lg:h-32">
                <div className="text-2xl font-semibold uppercase">Scope Of Work</div>
              </div>
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="800" data-sal-easing="ease" className="md:col-span-1 col-span-2 lg:h-32">
                <p className="lg:w-2/3 w-full">Our UI/UX designs are driven by data, ensuring to deliver immersive experience to the targeted audience.</p>
              </div>
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500" data-sal-easing="ease" className="md:col-span-1 col-span-2 lg:h-32">
                <div className="text-2xl font-semibold uppercase">Contract Review</div>
              </div>
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="800" data-sal-easing="ease" className="md:col-span-1 col-span-2 lg:h-32">
                <p className="lg:w-2/3 w-full">We create engaging interfaces understanding how users interact with the system.</p>
              </div>
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500" data-sal-easing="ease" className="md:col-span-1 col-span-2 lg:h-32">
                <div className="text-2xl font-semibold  uppercase">Development</div>
              </div>
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="800" data-sal-easing="ease" className="md:col-span-1 col-span-2 lg:h-32">
                <p className="lg:w-2/3 w-full">We use the best development solutions, the right platforms, to create customized approach for each and every project.</p>
              </div>
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500" data-sal-easing="ease" className="col-span-2 lg:h-32">
                <button className="uppercase text-xl flex bg-primary-100 p-3 px-8 rounded-full">
                  <span>See Detail</span>
                  <div className="ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="" width="24" height="24">
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path fill={theme === "dark" ? "white" : "black"} d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-screen relative z-20 pt-16 pb-32">
        <div className="container px-8 mx-auto">
          <hr className={`w-full h-0 border-t mb-2 ${theme === "dark" ? "border-white" : "border-black"}`} />
          <div className="w-full">
            <div className="text-base px-1 flex items-center justify-between font-light helv uppercase">
              <i> Services </i>
              <p>03</p>
            </div>
          </div>
          <div className="w-full mt-8 grid grid-cols-1 gap-16">
            <div className="grid lg:grid-cols-4 grid-cols-1 gap-8 col-span-1 ">
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500" data-sal-easing="ease" className="col-span-2 md:col-span-1 lg:h-32">
                <div className="text-3xl font-semibold uppercase">
                  Digital <br className="hidden md:block" /> Branding
                </div>
              </div>
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500" data-sal-easing="ease" className="col-span-2 md:col-span-1 lg:h-32">
                <div className="text-3xl font-semibold uppercase">
                  UI/UX <br className="hidden md:block" /> Design
                </div>
              </div>
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500" data-sal-easing="ease" className="col-span-2 md:col-span-1 lg:h-32">
                <div className="text-3xl font-semibold uppercase">
                  ECommerce <br className="hidden md:block" /> Design
                </div>
              </div>
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500" data-sal-easing="ease" className="col-span-2 md:col-span-1 lg:h-32">
                <div className="text-3xl font-semibold  uppercase">
                  WebApp <br className="hidden md:block" /> Development
                </div>
              </div>
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500" data-sal-easing="ease" className="col-span-2 md:col-span-1 lg:h-32">
                <div className="text-3xl font-semibold  uppercase">
                  ECommerce <br className="hidden md:block" /> Development
                </div>
              </div>
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500" data-sal-easing="ease" className="col-span-2 md:col-span-1 lg:h-32">
                <div className="text-3xl font-semibold  uppercase">
                  NO CODE <br className="hidden md:block" /> DEVELPOMENT
                </div>
              </div>
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500" data-sal-easing="ease" className="col-span-2 md:col-span-1 lg:h-32">
                <div className="text-3xl font-semibold  uppercase">
                  Application <br className="hidden md:block" /> Development
                </div>
              </div>
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500" data-sal-easing="ease" className="col-span-2 md:col-span-1 lg:h-32">
                <div className="text-3xl font-semibold  uppercase">
                  Design <br className="hidden md:block" /> System
                </div>
              </div>
              <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500" data-sal-easing="ease" className="col-span-2 lg:h-32">
                <button className="uppercase text-xl flex bg-primary-100 p-3 px-8 rounded-full">
                  <span>See Detail</span>
                  <div className="ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path fill={theme === "dark" ? "white" : "black"} d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-screen relative z-20 pt-16 pb-32">
        <div className="container px-8 mx-auto">
          <hr className={`w-full h-0 border-t mb-2 ${theme === "dark" ? "border-white" : "border-black"}`} />
          <div className="w-full">
            <div className="text-base px-1 flex items-center justify-between font-light helv uppercase">
              <i> Insights </i>
              <p>05</p>
            </div>
          </div>
          <div className="w-full mt-8 grid grid-cols-1 gap-16">
            <div className="grid lg:grid-cols-4 grid-cols-1 gap-8 col-span-1 ">
              {caseStudiesDetail.map((item, index) => (
                <CaseStudies key={index} imgSrc={item.image} description={item.description} title={item.title} link={item.link} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
